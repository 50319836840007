import { Routes } from '@angular/router';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { permissionCanUpdateGuardFactory } from '@app/shared/guards/permission.guard';
import { PermissionSectionChoices } from '@graphql/types';
import { BREADCRUMB_KEY } from '@ui';
import { ROUTE_DEFINITION } from '../shared/constants/route-definition.constant';
import { medicalExaminationTemplateRoutes } from './medical-examination-template/routes';
import { protectiveGearTemplateRoutes } from './protective-gear-template/routes';
import { toolTemplateRoutes } from './tool-template/routes';
import { trainingTemplateRoutes } from './training-template/routes';
import { vehicleTemplateRoutes } from './vehicle-template/routes';
import { workplaceInspectionsTemplateRoutes } from './workplace-inspections-template/routes';
import { insuranceContractTemplateRoutes } from '@app/settings-templates/insurance-contracts-template/routes';

export const settingsTemplatesRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_DEFINITION.SETTINGS_TEMPLATES.TRAINING,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.TRAINING,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'trainings' }),
    },
    children: trainingTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.TOOL,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'tool-template' }),
    },
    children: toolTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.PROTECTIVE_GEAR,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'protective-gear' }),
    },
    children: protectiveGearTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.MEDICAL_EXAMINATION,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'medical-examination' }),
    },
    children: medicalExaminationTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.WORKPLACE_INSPECTIONS,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'workplace-inspections' }),
    },
    children: workplaceInspectionsTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.VEHICLE,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'vehicles' }),
    },
    children: vehicleTemplateRoutes,
  },
  {
    path: ROUTE_DEFINITION.SETTINGS_TEMPLATES.INSURANCE_CONTRACTS,
    canActivate: [permissionCanUpdateGuardFactory(PermissionSectionChoices.Templates)],
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'insurance-contracts' }),
    },
    children: insuranceContractTemplateRoutes,
  },
];
